import React from 'react';
import { Link } from 'gatsby';

import Container from 'components/Container';

const Footer = () => {
    return (
        <footer>
            <Container className="footer">
                <p>
                    &copy; {new Date().getFullYear()}, Perspective Daily D&D Team
                    <br />
                    <a href="https://perspective-daily.de/impressum">Impressum</a>
                </p>
                <Link to="/faq/">FAQ</Link>
            </Container>
        </footer>
    );
};

export default Footer;
