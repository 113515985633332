import React from 'react';
import { Link } from 'gatsby';
import logo from 'assets/images/pd_dev_logo.png';

const Header = () => {
  return (
    <header className="header_container">
      <Link to="/">
        <img src={logo} alt="pd_dev logo" className="logo" />
      </Link>
      <ul>
        <li>
          <a href="https://discussions.dev.perspective-daily.de/">Diskussion</a>
        </li>
        <li>
          <Link to="/archive/">Archiv</Link>
        </li>
      </ul>
    </header>
  );
};

export default Header;
